import { scrollToY } from '@/js/helpers/scroll'
import docReady from '@/js/helpers/doc-ready'

docReady(() => {
  const links = document.querySelectorAll('a[href*="#"]:not([href="#"])')

  if (links.length) {
    for (let i = 0; i < links.length; i++) {
      links[i].addEventListener('click', function (e) {
        const target = this.hash
        const targetEl = document.getElementById(target.slice(1))

        if (targetEl) {
          const rect = targetEl.getBoundingClientRect()
          const offset = rect.top - 90

          scrollToY(offset, 1000)
        }
      })
    }
  }
})
