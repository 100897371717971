import docReady from '@/js/helpers/doc-ready'
import { TweenMax, Power1 } from 'gsap'

function randomInteger (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

docReady(() => {
  const colorBoxes = document.querySelectorAll('.color-box')
  const docHeight = document.body.clientHeight
  const width = window.innerWidth

  for (let i = 0; i < colorBoxes.length; i++) {
    const dataSrc = colorBoxes[i].getAttribute('data-src')
    // eslint-disable-next-line
    const downloadingImage = new Image()
    downloadingImage.onload = function () {
      colorBoxes[i].src = this.src

      TweenMax.set(colorBoxes[i], {
        top: randomInteger(-500, docHeight),
        left: randomInteger(-500, width),
        width: randomInteger(500, 1450)
      })
      TweenMax.to(colorBoxes[i], 3, {
        ease: Power1.easeOut,
        opacity: 1
      })
    }
    downloadingImage.src = dataSrc
  }
})
