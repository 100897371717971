import throttle from '@/js/helpers/throttle'
import docReady from '@/js/helpers/doc-ready'
import { gsap } from 'gsap'

class Navigation {
  constructor (el) {
    this.el = el
    this.body = document.body
    this.hamburger = this.el.querySelector('.nav-hamburger')
    this.toggleElements = this.body.querySelectorAll('.navbar-toggle')
    this.lastScrollTop = 0
    this.linkElements = document.querySelectorAll('a:not([target="_blank"]):not([href^="#"]):not([href^="mailto:"]):not([href^="tel:"])')
    this.stickyShare = this.body.querySelector('.project-share--sticky')
    this.hero = this.body.querySelector('.hero')
    this.menuActive = false

    this.initEvents()
  }

  toggleMenu () {
    this.menuActive = !this.menuActive

    this.body.classList.toggle('body--slide-active', this.menuActive)
    this.el.classList.toggle('nav--active', this.menuActive)
    this.hamburger.classList.toggle('nav-hamburger--is-active', this.menuActive)
    this.stickyShare.classList.toggle('project-share--hide', this.menuActive)
  }

  headerScroll (ws) {
    const addMenuScrollClass = ws > 30

    this.body.classList.toggle('menu-scroll', addMenuScrollClass)
    this.el.classList.toggle('menu-scroll', addMenuScrollClass)

    if (this.hero && this.stickyShare) {
      const heroHeight = this.hero.offsetHeight
      const showStickyShare = ws > heroHeight

      gsap.to(this.stickyShare, 0.3, {
        y: showStickyShare ? 0 : -25,
        autoAlpha: showStickyShare ? 1 : 0
      })
    }

    this.lastScrollTop = ws
  }

  initEvents () {
    const clickHandler = () => this.toggleMenu()

    for (let i = 0; i < this.toggleElements.length; i++) {
      this.toggleElements[i].addEventListener('click', clickHandler)
    }

    window.addEventListener('scroll', throttle(() => {
      const ws = window.pageYOffset
      this.headerScroll(ws)
    }, 100))

    document.addEventListener('touchmove', throttle(() => {
      const ws = window.pageYOffset
      this.headerScroll(ws)
    }, 100))

    this.headerScroll(0)
  }
}

docReady(() => {
  const menu = document.querySelector('.nav')
  if (menu) {
    // eslint-disable-next-line
    const nav = new Navigation(menu)
  }
})
