import Ajax from '@/js/helpers/ajax'
import docReady from '@/js/helpers/doc-ready'
import { TweenMax, Power3 } from 'gsap'

const ajax = new Ajax()
let postWrapper = false

const slideRequest = (e, wrapper) => {
  e.preventDefault()
  const root = e.target.closest('[data-page-slide]')
  const entry = wrapper.querySelector('.ajax-wrap__entry')
  const params = `?action=ajax_post&id=${root.getAttribute('data-id')}`
  const url = ajaxurl + params

  // empty
  if (entry.firstChild) {
    while (entry.firstChild) {
      entry.removeChild(entry.firstChild)
    }
  }

  // add loading state
  wrapper.classList.add('ajax-wrap--show', 'ajax-wrap--loading')

  ajax.get(url, res => {
    // remove loading state
    wrapper.classList.remove('ajax-wrap--loading')
    wrapper.classList.add('ajax-wrap--done')
    entry.insertAdjacentHTML('beforeend', res)
    const slidePost = wrapper.querySelector('.slide-post')

    document.addEventListener('click', wrapClick)

    if (slidePost) {
      TweenMax.to(slidePost, 0.3, {
        ease: Power3,
        opacity: 1,
        y: 0
      })
    }
  })
}

const wrapClick = e => {
  const clickOnWrap = e.target.closest('.ajax-wrap')
  if (!clickOnWrap) {
    if (e.target.closest('[data-page-slide]')) {
      slideRequest(e, postWrapper)
    } else {
      closeSlide(postWrapper)
    }
  }

  document.removeEventListener('click', wrapClick)
}

const closeSlide = wrapper => {
  const slidePost = wrapper.querySelector('.slide-post')

  if (slidePost) {
    TweenMax.to(slidePost, 0.1, {
      ease: Power3,
      opacity: 0
    })
  }

  wrapper.classList.remove('ajax-wrap--show', 'ajax-wrap--done')
}

docReady(() => {
  const pageSlideLinks = document.querySelectorAll('[data-page-slide]')
  const closeButton = document.querySelector('.ajax-wrap__close')
  postWrapper = document.querySelector('.ajax-wrap')

  if (closeButton) {
    const closeClickHandler = () => closeSlide(postWrapper)
    closeButton.addEventListener('click', closeClickHandler)
  }

  if (pageSlideLinks.length) {
    for (let i = 0; i < pageSlideLinks.length; i++) {
      const openClickHandler = e => slideRequest(e, postWrapper)
      pageSlideLinks[i].addEventListener('click', openClickHandler)
    }
  }
})
