// Modernizr
import 'root/.modernizrrc'

import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'

// Stylesheets
import '@/scss/style'

// Scripts
import '@/js/components/intro'
import '@/js/components/navigation'
import '@/js/components/video'
import '@/js/components/page-slide'
import '@/js/components/slider'
import '@/js/components/color-boxes'
import '@/js/components/map'
import '@/js/components/animation'
import '@/js/components/hash'
import '@/js/components/radio'
import '@/js/components/rellax'
import '@/js/components/crowns'
// import '@/js/components/form'
// import '@/js/components/search'

gsap.registerPlugin(CSSPlugin)
