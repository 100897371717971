import docReady from '@/js/helpers/doc-ready'
import throttle from '@/js/helpers/throttle'
import { gsap } from 'gsap'

export const animateBlocks = () => {
  const wh = window.innerHeight
  const animationEls = document.querySelectorAll('.s-animate:not(.s-animate-complete)')

  if (animationEls && animationEls.length) {
    for (let i = 0; i < animationEls.length; i++) {
      const rect = animationEls[i].getBoundingClientRect()
      const inView = rect.top < wh

      if (inView) {
        gsap.to(animationEls[i], 1, {
          ease: 'power3.out',
          opacity: 1,
          y: 0,
          onComplete () {
            gsap.set(animationEls[i], {
              clearProps: 'all'
            })
            animationEls[i].classList.add('s-animate-complete')
          }
        })
      }
    }
  } else {
    window.removeEventListener('load', throttledAnimation)
    window.removeEventListener('scroll', throttledAnimation)
    document.removeEventListener('touchmove', throttledAnimation)
    window.removeEventListener('resize', throttledAnimation)
    window.removeEventListener('orientationchange', throttledAnimation)
  }
}

const throttledAnimation = throttle(animateBlocks, 100)

const scrollAnimations = () => {
  const animationEls = document.querySelectorAll('.s-animate')

  if (animationEls && animationEls.length) {
    for (let i = 0; i < animationEls.length; i++) {
      const rect = animationEls[i].getBoundingClientRect()
      const inView = rect.top < window.innerHeight

      if (!inView) {
        gsap.set(animationEls[i], {
          opacity: 0,
          y: 100
        })
      }
    }

    animateBlocks()

    window.addEventListener('scroll', throttledAnimation)
    document.addEventListener('touchmove', throttledAnimation)
    window.addEventListener('resize', throttledAnimation)
    window.addEventListener('orientationchange', throttledAnimation)
  }
}

docReady(() => {
  scrollAnimations()

  const cards = document.querySelectorAll('.card')

  function myCompleteAll () {
    if (cards.length) {
      for (let i = 0; i < cards.length; i++) {
        cards[i].classList.add('card--complete')
      }
      gsap.set(cards, {
        clearProps: 'all'
      })
    }
  }

  if (cards.length) {
    gsap.set(cards, {
      opacity: 0,
      y: 100
    })
    gsap.to(cards, 1, {
      ease: 'power3.out',
      opacity: 1,
      y: 0,
      stagger: 0.15,
      onComplete () {
        myCompleteAll()
      }
    })
  }
})
