import docReady from '@/js/helpers/doc-ready'
import { gsap } from 'gsap'
import { animateBlocks } from '@/js/components/animation'

docReady(() => {
  const intro = document.getElementById('intro')
  const nav = document.querySelector('.nav')

  if (intro) {
    const tl = gsap.timeline()
    const logo = intro.querySelector('.intro__logo')
    const tagline = intro.querySelector('.intro__tagline')
    const container = intro.querySelector('.intro__container')

    tl.set('.hero__container', {
      ease: 'power3.out',
      y: 50,
      opacity: 0
    }, 0)

    tl.to(nav, 0.1, {
      y: '-100%'
    }, 0)

    tl.staggerFromTo([logo, tagline], 0.2, {
      opacity: 0,
      y: 15
    }, {
      ease: 'power3.out',
      opacity: 1,
      y: 0,
      stagger: 0.4
    }, 0.3)

    tl.to(container, 0.2, {
      ease: 'power3.out',
      scale: 1.02
    }, 1.2)

    tl.to(logo, 0.1, {
      ease: 'power1.out',
      fill: '#000'
    }, 1.4)

    tl.to(tagline, 0.1, {
      ease: 'power1.out',
      color: '#000'
    }, 1.4)

    tl.to(container, 0.3, {
      ease: 'power3.out',
      opacity: 0,
      scale: 0.95
    }, 1.5)

    tl.to(intro, 2, {
      opacity: 0
    }, 1.7)

    tl.to(nav, 1, {
      ease: 'power3.out',
      y: 0
    }, 2.5)

    tl.to('.hero__container', 1, {
      ease: 'power3.out',
      y: 0,
      opacity: 1,
      onComplete () {
        animateBlocks()
      }
    }, 2.6)
  }
})
