import Rellax from 'rellax'
import docReady from '@/js/helpers/doc-ready'

docReady(() => {
  // eslint-disable-next-line
  const rellax = new Rellax('.color-box', {
    center: false,
    wrapper: null,
    round: true,
    vertical: true,
    horizontal: false
  })
})
