/* eslint-disable */
export const snazzy = [
  {
    'featureType': 'all',
    'elementType': 'all',
    'stylers': [
      {
        'saturation': -100
      },
      {
        'gamma': 0.5
      }
    ]
  },
  {
    'featureType': 'all',
    'elementType': 'geometry',
    'stylers': [
      {
        'visibility': 'on'
      }
    ]
  },
  {
    'featureType': 'administrative',
    'elementType': 'all',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'administrative',
    'elementType': 'labels',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'administrative.country',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'visibility': 'on'
      }
    ]
  },
  {
    'featureType': 'administrative.country',
    'elementType': 'labels.text',
    'stylers': [
      {
        'color': '#e90303'
      }
    ]
  },
  {
    'featureType': 'landscape.man_made',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'visibility': 'on'
      },
      {
        'lightness': '-10'
      }
    ]
  },
  {
    'featureType': 'landscape.man_made',
    'elementType': 'geometry.stroke',
    'stylers': [
      {
        'visibility': 'on'
      },
      {
        'color': '#000000'
      },
      {
        'saturation': '66'
      },
      {
        'lightness': '-92'
      },
      {
        'gamma': '1.76'
      },
      {
        'weight': '0.20'
      }
    ]
  },
  {
    'featureType': 'landscape.man_made',
    'elementType': 'labels.text',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi',
    'elementType': 'labels',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'road',
    'elementType': 'labels',
    'stylers': [
      {
        'visibility': 'on'
      }
    ]
  },
  {
    'featureType': 'transit',
    'elementType': 'labels',
    'stylers': [
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'water',
    'elementType': 'geometry.fill',
    'stylers': [
      {
        'visibility': 'on'
      },
      {
        'saturation': '40'
      },
      {
        'color': '#434343'
      }
    ]
  }
]
