import docReady from '@/js/helpers/doc-ready'
import throttle from '@/js/helpers/throttle'
import EmblaCarousel from 'embla-carousel'
import Autoplay from 'embla-carousel-autoplay'
import ClassNames from 'embla-carousel-class-names'

docReady(() => {
  const crownsWrappers = document.querySelectorAll('[data-crowns]')

  if (crownsWrappers) {
    setCrowns()
    setEmbla()
  }
})

const setEmbla = () => {
  const emblaNodes = document.querySelectorAll('.embla')
  const options = {
    axis: 'y',
    loop: true,
    align: 1,
    containScroll: 'trimSnaps'
  }

  const autoplayOptions = {
    delay: 2000,
    stopOnInteraction: false
  }

  Array.from(emblaNodes).forEach(el => {
    // eslint-disable-next-line
    const emblaApi = EmblaCarousel(el, options, [Autoplay(autoplayOptions), ClassNames()])
  })
}

const CROWN_HEIGHT = 93 + 30
const NAVBAR_HEIGHT = 90
const PADDING_BOTTOM = 97

const setCrowns = () => {
  const crownsWrappers = document.querySelectorAll('[data-crowns]')
  const heroHeight = window.innerWidth < 1024 ? 700 : window.innerHeight

  Array.from(crownsWrappers).forEach((el) => {
    const crownsElements = el.querySelectorAll('[data-crown]')

    if (crownsElements) {
      el.classList.toggle('overflowing', (crownsElements.length * CROWN_HEIGHT) + PADDING_BOTTOM + NAVBAR_HEIGHT > heroHeight)
    }
  })
}

const throttledCrowns = throttle(setCrowns, 33)

window.addEventListener('resize', throttledCrowns)
window.addEventListener('orientationchange', throttledCrowns)
