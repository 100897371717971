import Plyr from 'plyr'
import docReady from '@/js/helpers/doc-ready'
import { TweenMax, Power3 } from 'gsap'

const lazyVideo = () => {
  const lazyVideos = [].slice.call(document.querySelectorAll('video.lazy'))

  if ('IntersectionObserver' in window) {
    // eslint-disable-next-line
    const lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (video) {
        if (video.isIntersecting) {
          for (const source in video.target.children) {
            const videoSource = video.target.children[source]
            if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
              videoSource.src = videoSource.dataset.src
            }
          }

          video.target.load()
          video.target.classList.remove('lazy')
          TweenMax.to(video.target, 1, {
            opacity: '1',
            ease: Power3.easeOut,
            delay: 1
          })
          lazyVideoObserver.unobserve(video.target)
        }
      })
    })

    lazyVideos.forEach(function (lazyVideo) {
      lazyVideoObserver.observe(lazyVideo)
    })
  }
}

docReady(() => {
  const players = document.querySelectorAll('.plyr-player')
  if (players.length) {
    for (let i = 0; i < players.length; i++) {
      // eslint-disable-next-line
      const plyrPlayer = new Plyr(players[i], {
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']
      })
    }
  }

  lazyVideo()
})
